import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"

const images = [{url:"/angela-goh-1.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Being Different</div><div className="dimensions"><span>Wood, epoxy, clay figurines</span><span>125 x 31 x 31 cm</span>
<span>Edition of 10</span></div></div>},{url:"/angela-goh-3.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Play Ball </div><div className="dimensions"><span>Clay</span><span>30 x 22 x 13 cm</span>
</div></div>}]
const images_highlight = [
  {url:"/isabel-4.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-5.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Creation of Man</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-6.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]
const images_highlight2 = [
  {url:"/isabel-7.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-1.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Creation of Man</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-2.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]

const IndexPage = () => (<div>
  <Layout>
    <SEO title="Services" />
    <div style={{ }}>
      <h1 style={{marginBottom:"60px", fontSize:"36px",marginTop:"80px", textAlign:"center"}}>Terms and Conditions</h1>
      <ImageRow rowType={"largeRow"} images={[{type:"text", text: <div >
        <p><br/><br/>
        By using the Frances Keevil web site, you agree to be bound by these terms of use and also to the provisions of our Copyright Statement. We reserve the right at any time to change or add to this web site, including the terms of use.
        If any provision of these terms is found to be invalid or unenforceable by a court of law, such invalidity or unenforceability will not affect the remainder of the terms of use which will continue in full force and effect.
        <br/><br/><br/><b> COPYRIGHT NOTICE AND ARTISTS' COPYRIGHT PROTECTION</b><br/>
        This web site is the copyright property of Frances Keevil and all works featured are also protected by copyright law. All images are protected by international copyright laws and are the property of the artist.
        You may download, display or print and reproduce this material in unaltered form only (retaining this notice) for your personal, non-commercial use. Apart from any use as permitted under the Copyright Act, 1968, you may not, in any form or by any means:
            <br/>1. adapt, reproduce, store, distribute, transmit, print, display, perform, publish or create derivative works from any part of this web site; or
          <br/>  2. commercialize any information, products or services obtained from any part of this web site, without the written permission of Frances Keevil or the respective artist.
        <br/><br/><b> TRADE MARKS</b>
        <br/>This web site contains trade marks of Frances Keevil. No licence, interest or right to or in any such trade marks of Frances Keevil is granted to or conferred upon you. Any reproduction or use of any of the content on this site, without the express written consent of Frances Keevil or unless expressly authorized by law, is prohibited. If you use any of the Frances Keevil trade marks to refer to our activities, products or services, you must include a statement attributing those trade marks to us. You must not use any of our trade marks:
          <br/>  1. in or as the whole or part of your own trade marks;
          <br/>  2. in connection with activities, products or services which are not ours;
          <br/>  3. in a manner which may be confusing, misleading or deceptive; 
          <br/>  4. in a manner that disparages us or our information, products or services.




      <br/><br/> <b> LINKED WEB SITES</b>
      <br/>  This web site may contain links to other web sites. Those links are provided for convenience only and may not remain current or be maintained. Links to those web sites should not be construed as any endorsement, approval, recommendation or preference by us of the owners or operators of those web sites, or for any information, products or services referred to on those other web sites. Any party seeking to link to this site either directly or from within a frameset must first seek consent from Frances Keevil.
      <br/><br/> <b>  CONTACT DETAILS</b><br/>
        If you have any further questions regarding the Frances Keevil web site or the services offered by Frances Keevil please contact Frances Keevil direct on +61 411 821 550 or email frances@franceskeevil.com.au 
      <br/><br/></p>  </div>, span:"12"}] } />
      </div>


  </Layout>

</div>
)

export default IndexPage
